import './App.css';
import WavesThreeJS from './components/WavesThreeJS';

function App() {
    return (
        <WavesThreeJS/>
    );
}

export default App;
