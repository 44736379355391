function Light() {
    return (
        <>
            <ambientLight intensity={4.0} />
            {/* <pointLight position={[1000, 1000, 100]} intensity={10} color="white" />
            <pointLight position={[1000, -500, 0]} intensity={1.5} color="green" />
            <pointLight position={[0, 0, 0]} intensity={50000} color="red" /> */}
        </>
    );
}

export default Light